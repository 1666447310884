var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitUpdate.apply(null, arguments)
        },
      },
    },
    [
      _c("h3", { staticClass: "text-lead mb-2" }, [
        _vm._v("Área: " + _vm._s(_vm.area.name)),
      ]),
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "col-md-8" }, [
          _c("div", { staticClass: "card mb-0" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h4", [_vm._v("Posts")]),
              _c("small", { staticClass: "d-block" }, [
                _vm._v("Posts vinculados a área " + _vm._s(_vm.area.name)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "draggable",
                  {
                    staticClass: "list-group list-group-flush cursor-move",
                    attrs: { tag: "ul" },
                    model: {
                      value: _vm.area.posts,
                      callback: function ($$v) {
                        _vm.$set(_vm.area, "posts", $$v)
                      },
                      expression: "area.posts",
                    },
                  },
                  _vm._l(_vm.area.posts, function (item, index) {
                    return _c(
                      "b-list-group-item",
                      {
                        key: index,
                        staticClass: "pl-0 pr-0",
                        attrs: { tag: "li" },
                      },
                      [
                        _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-1 text-center d-none d-sm-block",
                            },
                            [_c("i", { staticClass: "bi bi-arrows-move" })]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-10 col-7 mail-message" },
                            [
                              _c(
                                "b-media",
                                {
                                  staticClass: "text-truncate",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "aside",
                                        fn: function () {
                                          return [
                                            item.image_url
                                              ? _c("b-img", {
                                                  attrs: {
                                                    rounded: "",
                                                    src: item.image_url,
                                                    "blank-color": "#ccc",
                                                    width: "64",
                                                    alt: "placeholder",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("b-card-text", [
                                    _vm._v(" " + _vm._s(item.title) + " "),
                                    _c("br"),
                                    _c(
                                      "small",
                                      {
                                        staticStyle: { "font-size": "0.9rem" },
                                      },
                                      [
                                        _c("strong", [_vm._v("Criado:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("datePT")(
                                                item.created_at,
                                                true
                                              )
                                            ) +
                                            " "
                                        ),
                                        item.updated_at
                                          ? _c("span", [
                                              _vm._v(" - "),
                                              _c("strong", [
                                                _vm._v("Atualizado"),
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm._f("datePT")(
                                                      item.updated_at,
                                                      true
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-1 col-2 text-center" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "blog-posts-edit",
                                      params: { uuid: item.uuid },
                                    },
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "cursor-pointer cursor mx-1",
                                    attrs: { icon: "EditIcon", size: "16" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c("div", { staticClass: "card mb-2" }, [
              _c("div", { staticClass: "card-header" }, [_vm._v("Opções")]),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-2" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass:
                              "custom-control-areas custom-control-success float-left",
                            attrs: {
                              name: "check-button",
                              switch: "",
                              inline: "",
                              checked: _vm.area.display_blog_home,
                              value: 1,
                            },
                            model: {
                              value: _vm.area.display_blog_home,
                              callback: function ($$v) {
                                _vm.$set(_vm.area, "display_blog_home", $$v)
                              },
                              expression: "area.display_blog_home",
                            },
                          },
                          [
                            _c("span", { staticClass: "switch-icon-left" }, [
                              _c("i", { staticClass: "bi bi-check" }),
                            ]),
                            _c("span", { staticClass: "switch-icon-right" }, [
                              _c("i", { staticClass: "bi bi-x" }),
                            ]),
                          ]
                        ),
                        _vm._v(" Página "),
                        _c("strong", [_vm._v("Início")]),
                        _vm._v(" do "),
                        _c("strong", [_vm._v("BLOG")]),
                        _vm._v(". "),
                      ],
                      1
                    ),
                    _c("hr"),
                  ]),
                  _c("div", { staticClass: "col-md-12" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              placeholder: "Digite o título do produto",
                              options: _vm.optionsLayouts,
                            },
                            model: {
                              value: _vm.area.layout_blog_home,
                              callback: function ($$v) {
                                _vm.$set(_vm.area, "layout_blog_home", $$v)
                              },
                              expression: "area.layout_blog_home",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-12" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.area.color_blog_home,
                            expression: "area.color_blog_home",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.area.color_blog_home },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.area,
                              "color_blog_home",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-12" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "form-group mb-0" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.area.limit_blog_home,
                            expression: "area.limit_blog_home",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number" },
                        domProps: { value: _vm.area.limit_blog_home },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.area,
                              "limit_blog_home",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("ButtonsActionsFooter", {
              attrs: {
                variant: "success",
                submited: _vm.submited,
                text: "Salvar",
                subtext: "Aguarde...",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "" } }, [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Layout "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "" } }, [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Cor "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "" } }, [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Quant. de posts a exibir? "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }