<template>
  <form @submit.prevent="submitUpdate">
    <h3 class="text-lead mb-2">Área: {{ area.name }}</h3>
    <div class="form-row">
      <div class="col-md-8">
        <div class="card mb-0">
          <div class="card-header">
            <h4>Posts</h4>
            <small class="d-block"
              >Posts vinculados a área {{ area.name }}</small
            >
          </div>
          <div class="card-body">
            <draggable
              v-model="area.posts"
              class="list-group list-group-flush cursor-move"
              tag="ul"
            >
              <b-list-group-item
                v-for="(item, index) in area.posts"
                :key="index"
                tag="li"
                class="pl-0 pr-0"
              >
                <div class="form-row">
                  <div class="col-md-1 text-center d-none d-sm-block">
                    <i class="bi bi-arrows-move"></i>
                  </div>
                  <div class="col-md-10 col-7 mail-message">
                    <b-media class="text-truncate">
                      <template #aside>
                        <b-img
                          v-if="item.image_url"
                          rounded
                          :src="item.image_url"
                          blank-color="#ccc"
                          width="64"
                          alt="placeholder"
                        />
                      </template>
                      <b-card-text>
                        {{ item.title }} <br />
                        <small style="font-size: 0.9rem">
                          <strong>Criado:</strong>
                          {{ item.created_at | datePT(true) }}
                          <span v-if="item.updated_at">
                            - <strong>Atualizado</strong>:
                            {{ item.updated_at | datePT(true) }}
                          </span>
                        </small>
                      </b-card-text>
                    </b-media>
                  </div>
                  <div class="col-md-1 col-2 text-center">
                    <router-link
                      :to="{
                        name: 'blog-posts-edit',
                        params: { uuid: item.uuid },
                      }"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="cursor-pointer cursor mx-1"
                        size="16"
                      />
                    </router-link>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card mb-2">
          <div class="card-header">Opções</div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-2">
                  <b-form-checkbox
                    class="custom-control-areas custom-control-success float-left"
                    name="check-button"
                    switch
                    inline
                    :checked="area.display_blog_home"
                    v-model="area.display_blog_home"
                    :value="1"
                  >
                    <span class="switch-icon-left">
                      <i class="bi bi-check"></i>
                    </span>
                    <span class="switch-icon-right">
                      <i class="bi bi-x"></i>
                    </span>
                  </b-form-checkbox>
                  Página <strong>Início</strong> do <strong>BLOG</strong>.
                </div>
                <hr />
              </div>
              <div class="col-md-12">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Layout
                </label>
                <div class="form-group">
                  <v-select
                    label="title"
                    item-text="title"
                    item-value="code"
                    v-model="area.layout_blog_home"
                    placeholder="Digite o título do produto"
                    :options="optionsLayouts"
                  >
                    <span slot="no-options"> Nenhum registro encontrado </span>
                  </v-select>
                </div>
              </div>
              <div class="col-md-12">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Cor
                </label>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="area.color_blog_home"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Quant. de posts a exibir?
                </label>
                <div class="form-group mb-0">
                  <input
                    type="number"
                    class="form-control"
                    v-model="area.limit_blog_home"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ButtonsActionsFooter
          variant="success"
          :submited="submited"
          text="Salvar"
          subtext="Aguarde..."
        />
      </div>
    </div>
  </form>
</template>

<script>
import {
  BCard,
  BListGroupItem,
  BCardBody,
  BCardText,
  BSpinner,
  BListGroup,
  BAvatar,
  BFormCheckbox,
  BMedia,
  BImg,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";

export default {
  components: {
    BImg,
    BFormCheckbox,
    BListGroupItem,
    BListGroup,
    BMedia,
    BAvatar,
    BCard,
    BCardBody,
    BCardText,
    draggable,
    BSpinner,
    vSelect,
    ButtonsActionsFooter,
  },
  data() {
    return {
      category: "",
      submited: false,
      posts: [],
      area: {
        posts: [],
      },
      loading: false,
      optionsLayouts: [
        {
          title: "Mini card",
          code: 1,
        },
        {
          title: "Big card",
          code: 2,
        },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    submitUpdate() {
      this.loading = true;
      this.$store
        .dispatch(`Post/areaUpdate`, {
          uuid: this.$route.params.uuid,
          data: this.area,
        })
        .then(() => {
          this.notifyDefault("success");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getData() {
      this.loading = true;
      this.$store
        .dispatch(`Post/area`, this.$route.params.uuid)
        .then((data) => {
          this.area = data;

          this.area.display_blog_home ? true : false;

          if (this.area.layout_blog_home === "1") {
            this.area.layout_blog_home = this.optionsLayouts[0];
          } else {
            this.area.layout_blog_home = this.optionsLayouts[1];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.custom-control-areas .switch-icon-right,
.custom-control-areas .switch-icon-left {
  line-height: 1.8rem;
}
</style>
